<template>
  <div class="checkout-items">
    <b-overlay
      :show="showProjectsLoading"
      blur="0"
      opacity=".75"
      rounded="sm"
      spinner-variant="primary"
      variant="white"
    >
      <b-card
        v-for="project in projects"
        :key="project.id"
        class="project-card"
        no-body
      >
        <div class="item-img">
          <b-link>
            <b-img
              :alt="`${project.name}-${project.id}`"
              :src="project != null && project.avatarPath != null
                ? `data:image/png;base64,${project.avatarPath}`
                : require('@/assets/images/avatars/13-small.png')"
              style="margin-left: 15px;"
            />
          </b-link>
        </div>
        <b-card-body style="border-right:none; margin-left: 5px;">
          <div class="item-name">
            <h6 class="mb-0">
              <b-link class="text-body">
                {{ project.name }}
              </b-link>
            </h6>
          </div>
          <p style="margin-top: 5px;">
            {{ project.description }}
          </p>

          <div
            v-if="project.id === activeProjectId"
            class="item-cost"
          >
            <p class="card-text shipping">
              <b-badge
                pill
                variant="light-success"
              >
                Сейчас выбран
              </b-badge>
            </p>
          </div>

          <div
            v-if="!project.isEnabled"
            class="item-cost"
          >
            <p class="card-text shipping">
              <b-badge
                pill
                variant="light-warning"
              >
                Проект в архиве
              </b-badge>
            </p>
          </div>

          <span class="delivery-date text-muted">
            Создано {{
              formatDate(project.createdAt, {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit'
              })
            }}
          </span>
        </b-card-body>

        <div class="card-body" />

        <div class="item-options text-center">
          <b-button
            :to="{ path:`/projects/` + project.id }"
            class="remove-wishlist"
            variant="light"
          >
            <feather-icon
              class="mr-50"
              icon="Edit2Icon"
            />
            <span>Редактировать</span>
          </b-button>
          <b-button
            class="btn-cart move-cart"
            :variant="project.isEnabled ? 'primary' : 'warning'"
            @click="moveToArchive(project)"
          >
            <feather-icon
              :class="{'fill-current': project.isInWishlist}"
              class="mr-50"
              icon="ArchiveIcon"
            />
            <span
              v-if="project.isEnabled"
              class="text-nowrap"
            >Архивировать</span>
            <span
              v-if="!project.isEnabled"
              class="text-nowrap"
            >Убрать из архива</span>
          </b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {
  BBadge, BButton, BCard, BCardBody, BImg, BLink, BOverlay,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useEcommerceUi } from '../apps/e-commerce/useEcommerce'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BOverlay,
    BBadge,
  },
  data() {
    return {
      projects: [],
      showProjectsLoading: false,
    }
  },
  computed: {
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
  },
  mounted() {
    this.showProjectsLoading = true
    this.$http.get('/project/shorts')
      .then(res => {
        this.projects = res.data
        this.showProjectsLoading = false
      })
  },
  methods: {
    moveToArchive(project) {
      if (project.isEnabled) {
        this.$http.get(`/project/archive?projectId=${project.id}`)
          .then(res => {
            if (res) {
              this.showProjectsLoading = true
              this.$http.get('/project')
                .then(res1 => {
                  this.projects = res1.data
                  this.showProjectsLoading = false
                })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Проект добавлен в архив',
                  text: 'Статистика по данному проекту не будет обновляться',
                  icon: 'ArchiveIcon',
                  variant: 'success',
                },
              })
            }
          })
      } else {
        this.$http.get(`/project/fromarchive?projectId=${project.id}`)
          .then(res => {
            if (res) {
              this.showProjectsLoading = true
              this.$http.get('/project')
                .then(res1 => {
                  this.projects = res1.data
                  this.showProjectsLoading = false
                })

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Проект успешно достали из архива',
                  text: 'Статистика по данному проекту будет обновляться',
                  icon: 'ArchiveIcon',
                  variant: 'success',
                },
              })
            }
          })
      }
    },
  },
  setup() {
    const { toggleProductInWishlist } = useEcommerceUi()

    return {
      // UI
      toggleProductInWishlist,
      // Filter
      formatDate,
    }
  },
}
</script>

<style>
.second-column {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
</style>
