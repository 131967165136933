<template>
  <section
    class="projects-list"
    data-app
  >
    <div class="list-view mt-0">
      <b-row class="match-height">
        <b-col cols="2">
          <b-button
            id="add-project"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-2"
            :to="{ path:`/projects/new` }"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Добавить проект</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <projects-list />
        </b-col>
      </b-row>
    </div>
    <app-tour :steps="steps" />
  </section>
</template>

<script>

import {
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import ProjectsList from './ProjectsList.vue'

export default {
  components: {
    // SFC
    ProjectsList,
    BRow,
    BCol,
    BButton,
    AppTour,
  },
  data() {
    return {
      steps: [
        {
          target: '#add-project',
          header: {
            title: 'Добавление проекта',
          },
          content: 'Перейдите сюда для добавления проекта.',
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/projects-list.scss';
@import '@core/scss/vue/libs/tour.scss';
</style>
